/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
} 

body {
  font-family: Tahoma, sans-serif;
  line-height: 1.3;
  font-size: 15px;
  overflow-x: hidden;
  background-color: #282c34;
  color:#fff;  
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}  

h1, h2, h3, h4, h5, h6{ 
  font-weight: bold;
}
h1{
  margin: 1.2rem 0 1rem 0;
  font-size:1.5rem; 
}
h2{
  margin: 1.5rem 0 1rem 0;
  font-size:1.5rem
}

/* Styles */
.box {   
  position: relative;
  padding: 5px;
  border-radius: 3px;
  transition: all 0.3s ease;
  background: #242830;
  margin-top: 0.5rem;
}
.form_bar{
  display: flex;  
  flex: 1 1 400px;
  margin-bottom:10px;
  gap:1rem
}
.form_bar__input{
  flex: 2 1 3rem;
}
.form_bar__button{
  flex: 1 1 2rem;
}
form input, button{
  border: 1px solid #888888; 
  font-size: 1rem;
  padding: 8px;  
  outline: none;
  border-radius: 3px; 
  background-color: transparent; 
  color: #fff;
  transition:all 0.3s ease;  
}
form input:hover{
  border-color:#fff; 
}
.button, button, input[type="submit"] {
  background-color: rgb(0, 255, 132);
  border-color: inherit;   
  cursor: pointer; 
  color:#282c34;
  font-size: 1.2rem;
  font-weight: bold;
}
.button:hover, button:hover, input[type="submit"]:hover {
  background-color: rgb(115, 83, 188);
  border-color: rgb(115, 83, 188);;  
  color: #deffe9;
}
.topRightButton{
  position: absolute;
  right: 0;
  top: 0;
  background-color: #242424;
  width: 2rem;
  height: 2rem; 
  color: #ccc;
  font-size: 1rem;
  line-height: 1.9rem;
  text-align: center;
  border-radius: 3px; 
}

/*App Header*/

.App-header {  
  background-color: #16191f; 
  color:#fff; 
  margin-bottom:2rem;
  padding: 0 2rem;
  display: flex;
  justify-content:space-between;   
  align-items: center;    
}   
.App-header .MenuTogler{
  position: relative;
  padding-right:10px ;
}
.App-header .playlistCount {
  position: absolute;
  top: -10px;
  right: -4px;
  background-color: rgb(115, 83, 188);
  border-radius: 3px;
  padding: 1px 4px;
  font-size: 0.7rem;
  font-weight: bold;
}
.App-header .playlistCount.populated {
  background-color: rgb(0, 255, 132);
  color: #16191f;
}
.App-header .userMenu > ul{
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.App-header .userMenu .separator{
  margin: 0 1rem ;
  border-left: 1px solid #ccc;
}
 
.App-header .userMenu .userMenu__avatar{
  border-radius: 90rem;
  width: 2.4rem;
}

.App-row{
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 610px;
  align-items:flex-start;
}
.App-row > div {
  flex: 1 1 150px;
   
}
main .App-row{
  gap:2rem
} 

/* Tracklist and tracks */

.Tracklist{}
.Track{
  display: flex; 
  flex: 1 1 5.2rem ;   
}
.Track__info{
  margin: 4px 0 0 14px;   
}
.Track__info p{ 
  color: #ccc;   
}
.Track__thumbnail img{
  border-radius: 3px;
  max-width: 4.2rem;
}  
.Track:hover{
  background-color: #353942;
}
.Track:hover .topRightButton{
  display: block;
}
.Track.added .button{
  background-color: rgb(0, 255, 132);
  color:#16191f
}

/* Edit playlist */

.EditPlaylist{
  padding: 0 1rem 1rem 1rem;
}
.EditPlaylist__title{
  margin-bottom: 1.5rem;
}

/* Saved Playlist List*/ 
 
.SavedPlaylistList{
  background-color: #282c34;;
  width: 40%;
  height: 100vh;
  z-index: 90;
  position: absolute;
  right: -40%;
  top: 0;  
  -webkit-box-shadow: 2px 10px 31px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 10px 31px -9px rgba(0,0,0,0.75);
  box-shadow: 2px 10px 31px -9px rgba(0,0,0,0.75);
  padding: 3rem;
  transition:all 0.3s ease;
}
.SavedPlaylistList.open{
  right: 0; 
}
.SavedPlaylist{     
  display: flex; 
  gap:1rem;
}
.SavedPlaylist:hover{   
  background: #343944;
}
.SavedPlaylist:hover .topRightButton{
  display: block;
}
.SavedPlaylist__edit{
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;  
}
.SavedPlaylist__sync_to_spotify {
  font-size: 0.75rem; 
  margin-top: 0.25rem;
  padding: 0.25rem 1.5rem;
  display: inline-block;
  border-radius: 3rem;
  position: relative;
}

.lds-dual-ring {
  position: absolute;  
  display: block;
  top: 4px;
  right: 5px;
  width: 10px;
  height: 10px; 
  border-radius: 50%;
  border: 2px solid #000;
  border-color: #000000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Dropdown{
  cursor: pointer;
  position: relative;
}
.Dropdown__list{
   position: absolute;
   top: 1.5rem;
   right: 0;
   background-color: #fff;
   border-radius: 3px;
   width: 100%;
   color: #16191f;
}
.Dropdown__item {
  padding: 0.5rem 1rem;
}

.Pagination{
  margin: 1rem 0;
  display: flex;
  gap: 0.5rem; 
  flex-wrap: wrap;
}
.Pagination li{
  cursor: pointer;
  width: 2rem;
  text-align: center;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid#888888;
  color: #fff;
}

.Pagination li.current{
  background-color: rgb(0, 255, 132);
  color: #282c34;
  border-color: rgb(0, 255, 132);
}

.Login, .RequestToken {
  height: 100vh;
  display: flex;
  align-items: center; 
  width: 100%;
  justify-content: center;
}

.Login div, .RequestToken div{
  border: #353942 1px solid;
  border-radius:1rem;
  padding: 1rem 2rem 2rem 2rem;
  text-align: center;
  width: 60vh;
}
.Login button, .RequestToken button{
  width: 100%;
  margin: 2rem 0 1rem 0;
}